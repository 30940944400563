import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { find, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { PANTitle, toast } from "../../components";
import { usePermissions, useTranslate } from "../../customHooks";
import Emitter from "../../eventEmitter";
import { ReduxActions, ReduxResources } from "../../redux";
import { checkCSPBanner, GetRulesLength } from "../../utils";
import { getNotificationList, renderNotificationBanner } from "../../utils/checkSWUpgradeBanner";
import FirewallsCard from "./components/FirewallsCard";
import { GettingStartedCard } from "./components/GettingStartedCard";
import RulestacksCard from "./components/RulestacksCard";
import WelcomeCard from "./components/WelcomeCard";

const useStyles = makeStyles((theme) => ({
    overviewScreen: {
        "&>section": {
            "&>div": {
                "&>h2": {
                    paddingTop: "16px",
                    paddingBottom: "14px",
                },
            },
        },
    },
}));

const Overview = (props: any) => {
    const translate = useTranslate();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const reduxState = useAppSelector((state) => state);
    const ruleStacksState = reduxState.ruleStacks.list;
    const firewallsState = reduxState.firewalls.list;

    const [firewalls, setFirewalls] = useState(firewallsState.data);
    const [ruleStacks, setRuleStacks] = useState(ruleStacksState.data);
    const [localRuleStacks, setLocalRuleStacks] = useState([]);

    const classes = useStyles();
    const {permissions, rawPermissions} = usePermissions();
    const [loading, setLoading] = useState(true);
    const [localRulesStacks, setLocalRulesStacks] = useState([]);
    //const [rulesStacks, setRulesStacks] = useState([]);
    const [hasRules, setHasRules] = useState(false);
    const [loadingSteps, setLoadingSteps] = useState(false);
    const [error, setError] = useState(undefined);
    const [globalError, setGlobalError] = useState(undefined);
    const [rulesError, setRulesError] = useState(undefined);
    const [firewallError, setFirewallError] = useState(undefined);
    const [notificationList, setNotificationList] = useState<any[]>([]);

    const title = translate(`resources.overview.name`);

    useEffect(() => {
       if (reduxState.firewalls.listDetails.data && reduxState.firewalls.listDetails.data.length > 0) {
            setNotificationList(getNotificationList(reduxState.firewalls.listDetails.data));
       }
    }, [reduxState.firewalls.listDetails.data]);

    useEffect(() => {
        checkCSPBanner({translate, reduxState});
    }, [reduxState.support.cspEnable]);

    useEffect(() => {
        loadOverviewData();
    }, []);

    useEffect(() => {
        checkForRulesLength();
    }, [ruleStacks?.length, localRuleStacks?.length, permissions]);

    // useEffect(() => {
    //   checkForRulesLength();
    // }, [localRuleStacksLength, globalRuleStacksLength, permissions]);

//   const getCSPWorkflow = () => {
//     dataProvider
//       .describe("support")
//       .then(async (response: DataTypes.IFwaasApiResponse) => {
//         if (response.data) {
//           if (
//             response.data.SupportInformations &&
//             isEmpty(response.data.SupportInformations[0])
//           ) {
//             let newAppConfig =
//               ApplicationConfigManager.getInstance().getConfig();
//             newAppConfig.setCspWorkFlow = true;
//             await ApplicationConfigManager.getInstance().setConfig(
//               newAppConfig
//             );
//             // @ts-ignore
//             Emitter.emit("setCspWorkFlow");
//           }
//         } else {
//           toast.error(response?.error?.error);
//         }
//       })
//       .catch((e: any) => {
//         toast.error(e?.error);
//       });
//   };

    useEffect(() => {
        if (!ruleStacksState.loading && !firewallsState.loading && (!isEmpty(ruleStacksState.data) || !isEmpty(firewallsState.data))) {
            setFirewalls(firewallsState.data);
            setRuleStacks(ruleStacksState.data);
            setLocalRuleStacks(ruleStacksState.data.filter((r: { Scope: string; }) => r.Scope === 'Local'));
            setLoading(false);
            Emitter.emit("regionLoadingData", false);
            dispatch(ReduxActions.fetchListDetails({resource: ReduxResources.RULESTACK})({}));
            dispatch(ReduxActions.fetchListDetails({resource: ReduxResources.FIREWALL})({}));
            dispatch(ReduxActions.fetchListDetails({resource: ReduxResources.USER})({}));
        }
    }, [ruleStacksState.loading, firewallsState.loading])

    useEffect(() => {
        setLoading(false);
        Emitter.emit("regionLoadingData", false);
        if (!isEmpty(ruleStacksState.error)) {
            setError(ruleStacksState?.error)
            toast.error(ruleStacksState?.error, {toastId: "rulestacks-list"});
        }
        if (!isEmpty(firewallsState.error)) {
            setError(firewallsState?.error)
            toast.error(firewallsState?.error, {toastId: "firewalls-list"});
        }
    }, [ruleStacksState.error, firewallsState.error])

    const loadOverviewData = async () => {
        if (firewalls.length < 1 || ruleStacks.length < 1) {
            setLoading(true);
            Emitter.emit("regionLoadingData", true);
            (rawPermissions &&
                (rawPermissions.some((permission: any) => permission.Policy === "LocalFirewallAdmin" || permission.Policy === "GlobalFirewallAdmin")) &&
                dispatch(ReduxActions.fetchList({resource: ReduxResources.FIREWALL})({})));
            //await dispatch(fetchList({resource: ReduxResources.RULESTACK})({data: {'Scope': 'Local'}}));
        }
        // setLoading(true);
        // Emitter.emit("regionLoadingData", true);
        // Promise.all([
        //   rawPermissions &&
        //     find(rawPermissions, { Policy: "LocalFirewallAdmin" }) &&
        //     GetFirewallsLengthByAccount(setFirewallsLength, setLoading, setFirewallError),
        //   GetRuleStacksLength(
        //     setRuleStacksLength,
        //     setLoading,
        //     undefined,
        //     setRulesStacks,
        //     setGlobalError
        //     ),
        //   GetRuleStacksLength(
        //     setLocalRuleStacksLength,
        //     setLoading,
        //     "Local",
        //     setLocalRulesStacks,
        //     setError
        //   ),
        // ]).then(() => {
        //     setLoading(false);
        //   })
        //   .catch((error: any) => {
        //     setLoading(false);
        //     toast.error(error?.error, { toastId: "overview-list" });
        //   })
        //   .finally(() => {
        //     setLoading(false);
        //     Emitter.emit("regionLoadingData", false);
        //   })
    };

    const handleRegionChange = (region: any) => {
        setError(undefined);
        setRuleStacks([]);
        setFirewalls([]);
        setLocalRuleStacks([]);
        //setFirewallsLength(0);
        //setGlobalRuleStacksLength(0);
        //setLocalRuleStacksLength(0);
        loadOverviewData();
    };

    useEffect(() => {
        Emitter.on("regionChanged", handleRegionChange);
        return () => {
            Emitter.off("regionChanged", handleRegionChange);
        };
    }, []);

    const checkForRulesLength = async () => {
        setLoadingSteps(true);
        if (permissions) {
            try {
                if (ruleStacks.length > 0) {
                    if (
                        localRuleStacks.length > 0 &&
                        rawPermissions && find(rawPermissions, {Policy: "LocalRuleStackAdmin"})
                    ) {
                        for (let i = 0; i < localRuleStacks.length; i++) {
                            let result = await GetRulesLength(
                                permissions,
                                "Local",
                                setLoadingSteps,
                                localRuleStacks[i].Name,
                                undefined,
                                setRulesError
                            );
                            if (result && result[0] !== undefined) {
                                setLoadingSteps(false);
                                setHasRules(true);
                                break;
                            } else if (result && result[1] !== undefined) {
                                setLoadingSteps(false);
                                setHasRules(false);
                                toast.error(result[1], {toastId: "overview-list"});
                                break;
                            }
                        }
                    }
                } else {
                    setLoadingSteps(false);
                    setHasRules(false);
                    toast.error(error, {toastId: "overview-list"});
                }
            } catch (e: any) {
                setLoadingSteps(false);
                setHasRules(false);
                toast.error(e);
            }
        } else {
            setLoadingSteps(false);
            setHasRules(false);
            toast.error('no permissions', {toastId: "overview-list"});
        }
    };

    return (
        <section className={classes.overviewScreen}>
            <PANTitle
                region
                divider
                title={title}
                paddingContainer="16px"
                data-metrics="cloudngfw-overviewScreen-title"
            />
            <>
                {((notificationList && notificationList.length > 0) &&
                    notificationList.map((item: any) => {
                        return renderNotificationBanner(item);
                    }
                ))}
            </>
            <WelcomeCard/>
            {error === undefined ? (
                <Grid
                    data-metrics="cloudngfw-overviewScreen-container"
                    container
                    id="ngfw-left-grid"
                >
                    <Grid data-metrics="cloudngfw-overviewScreen-two-cards" item xs={6}>
                        <RulestacksCard
                            permission={rawPermissions}
                            loading={loading}
                            globalRuleStacksLength={ruleStacks.length > localRuleStacks.length ? ruleStacks.length - localRuleStacks.length : 0}
                            localRuleStacksLength={localRuleStacks.length}
                            isGlobalError={globalError}
                            error={error}
                        />
                        <FirewallsCard
                            permission={rawPermissions}
                            loading={loading}
                            firewallsLength={firewalls?.length}
                        />
                    </Grid>
                    <Grid
                        data-metrics="cloudngfw-overviewScreen-single-cards"
                        item
                        xs={6}
                    >
                        <GettingStartedCard
                            permission={rawPermissions}
                            loading={loadingSteps}
                            ruleStacksLength={ruleStacks.length || localRuleStacks.length}
                            firewallsLength={firewalls?.length}
                            hasRules={hasRules}
                        />
                    </Grid>
                </Grid>
            ) : (
                <Grid
                    data-metrics="cloudngfw-overviewScreen-container"
                    container
                    id="ngfw-left-grid"
                >
                    <Grid data-metrics="cloudngfw-overviewScreen-two-cards" item xs={6}>
                        <RulestacksCard readOnly={true}/>
                        <FirewallsCard readOnly={true}/>
                    </Grid>
                    <Grid
                        data-metrics="cloudngfw-overviewScreen-single-cards"
                        item
                        xs={6}
                    >
                        <GettingStartedCard readOnly={true}/>
                    </Grid>
                </Grid>
            )}
        </section>
    );
};

export default withRouter(Overview);
